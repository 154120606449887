// $validationContent: 83px;

.sso-verify-email {
  width: 29rem;
  &--header {
    margin-bottom: 16px;
    padding-top: 16px;
  }
  &--container {
    padding-left: 48px;
    padding-right: 48px;
  }
  &--email-info {
    color: #057cb1;
    margin-bottom: 24px;
    border: 1px solid #0267b8;
    border-radius: 4px;
    opacity: 1;
  }
  &--verification {
    &--info {
      color: #057cb1;
      margin-bottom: 16px;
      border: 1px solid #0267b8;
      border-radius: 4px;
      opacity: 1;
      height: 100%;
    }
    &--email {
      font-size: 18px;
      font-weight: bold;
      color: #3e3e3e;
      text-align: left;
      &--text {
        position: relative;
        bottom: 6px;
        left: 6px;
      }
    }
  }
  &--content {
    text-align: left;
    line-height: 1.25;
    letter-spacing: 0.1px;
    &--email-text {
      padding: 10px 20px 0 6px;
    }
    &--verification-text {
      padding: 10px 10px 10px 0;
      overflow-wrap: break-word;
    }
  }
  &--icon {
    &--email {
      color: #057cb1;
      margin-top: 36px;
    }
    &--verification {
      color: #057cb1;
      margin-top: 28px;
    }
  }
  &--button {
    margin-top: 32px;
  }
}

.btn.next-btn.sso-verify-email--button {
  margin-top: 32px;
  margin-bottom: 32px;
}
