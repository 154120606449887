.sso-password {
  &--back {
    text-align: left;
    height: 2.75rem;
    > button {
      padding: 20px 0 0 0;
      color: #0267b8;
      *p {
        color: blue;
      }
    }
  }
  &--visibility-button {
    position: relative;
    left: 4px;
    color: #3e3e3e;
  }
  &--username {
    padding-left: 4px;
    font-size: 18px;
    font-weight: bold;
    color: #3e3e3e;
    position: relative;
    bottom: 1px;
    max-width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &--forgotLink {
    font-size: 14px;
    color: #0267b8;
    cursor: pointer;
  }
}
