$paperDefaultPadding: 24px 64px 24px 64px;
$footerHeight: 40px;

body {
  background-color: #f5f5f5;
}

.router-container {
  background-color: #f5f5f5;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  height: calc(97vh - #{$footerHeight});

  &--logo {
    width: 340px;
  }
  &--section {
    &--placeholder {
      flex-basis: 1rem;
    }
    &--contents {
      width: 100vw;
      overflow-x: hidden;
      flex-grow: 1;
      text-align: center;
      &--layout {
        display: inline-block;
      }
    }
    &--help {
      flex-grow: 24;
      font-size: 14px;
      &--text {
        position: relative;
        top: 16px;
        text-decoration: none;
        color: #0267b8;
      }
    }
    &--footer {
      flex-basis: $footerHeight;
      flex: 1 0 auto;
      width: 100%;
      background-color: #3e3e3e;
      text-align: center;
      &--text {
        padding-top: 12px;
        display: inline-block;
        color: #cfcfcf;
        font-size: 12px;
        &--item {
          padding-left: 12px;
          padding-right: 12px;
          &--link {
            text-decoration: none;
            color: inherit;
          }
        }
      }
    }
  }
}
.paper {
  padding: $paperDefaultPadding;
}
.paper.paper-signin {
  width: 464px;
  height: 17rem;
}
.paper.paper-password {
  width: 464px;
  height: 20rem;
}

.paper h1 {
  text-align: center;
  font: Bold 32px/40px Noto Sans;
  letter-spacing: 0;
  color: #3e3e3e;
  opacity: 1;
}

.btn {
  width: 100%;
  font-size: 16px;
}
.btn.next-btn {
  height: 48px;
  text-transform: none;
}
