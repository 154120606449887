$validationContent: 83px;

.paper.paper-password {
  .validation {
    margin-bottom: 24px;
    border: 1px solid #0267b8;
    border-radius: 4px;
    opacity: 1;
    height: 103px;
    & > .MuiGrid-root > .MuiBox-root {
      height: $validationContent;
    }

    .content > div,
    h3 {
      font: Regular 16px/20px Noto Sans;
      letter-spacing: 0.1px;
      color: #057cb1;
    }
  }
}

.txtField.new-password--expired--padding {
  margin-bottom: 24px;
}

.new-password {
  width: 29rem;
  height: 42rem;

  &--expired {
    width: 30rem;
    height: 48.5rem;
    &--text {
      padding: 0 48px 12px 48px;
    }
  }

  &--header {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &--content {
    margin-left: 48px;
    margin-right: 48px;
  }

  &--info {
    margin-bottom: 16px;
    border: 1px solid #0267b8;
    border-radius: 4px;
    color: #057cb1;
    padding-top: 10px;
    padding-bottom: 10px;
    &--icon {
      margin-top: 90%;
    }
    &--content {
      text-align: left;
      > ul {
        padding-left: 1.25rem;
      }
      &--header {
        margin-bottom: 8px;
      }
    }
  }
  &--email {
    font-size: 18px;
    font-weight: bold;
    color: #3e3e3e;
    text-align: left;
    &--text {
      position: relative;
      bottom: 9px;
      left: 6px;
    }
  }

  &--button {
    margin-top: 32px;
  }
  .btn.next-btn.new-password--button {
    margin-top: 32px;
  }
}
