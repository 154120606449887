.sso-textfield {
  &--label {
    margin: 8px 0 0 0;
    text-align: start;
  }
  &--input {
    height: 2.75rem;
    background-color: #f9f9f9;
    > input {
      margin-right: 20px;
    }
  }
  &--validated-textfield-wrapper {
    > div {
      > svg {
        top: 2px;
        right: 7px;
      }
      > div > p {
        // targets the helper text (validation error message).  Move it left a bit so we
        // can fit a little bit longer text without wrapping
        margin-left: 8px;
      }
    }
  }
  &--textfield-wrapper {
    > div > svg {
      top: 2px;
      right: 7px;
    }
  }
}
